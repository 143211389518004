import clsx from "clsx";
import { forwardRef } from "react";
import type { LegacyRef, InputHTMLAttributes } from "react";

// eslint-disable-next-line @typescript-eslint/naming-convention -- using forwardRef
export const BaseInput = forwardRef(({
  type = "text",
  className,
  ...rest
}: InputHTMLAttributes<HTMLInputElement>, ref: LegacyRef<HTMLInputElement> | undefined) => <input className={clsx(className, "w-full appearance-none rounded-sm border border-solid border-silver py-1 pl-1 text-lg text-charcoal outline-none transition-all read-only:cursor-default read-only:!border-silver read-only:text-nobel focus:border-dim focus:ring-1 focus:ring-dim focus:read-only:ring-0 enabled:hover:border-dim disabled:bg-whisper disabled:text-nobel motion-reduce:transition-none lg:text-base")} ref={ref} type={type}
// eslint-disable-next-line react/jsx-props-no-spreading -- enabled to allow all props
{...rest} />);