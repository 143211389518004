import { clsx } from "clsx";
import type { ReactNode } from "react";
import { Spinner } from "@/components/Spinner/Spinner.component";
export type SubmitButtonProps = {
  readonly className?: string;
  readonly isPending?: boolean;
  readonly disabled?: boolean; // eslint-disable-line react/boolean-prop-naming -- The name should match the official one in HTML.
  readonly children?: ReactNode;
  readonly variant?: "primary" | "accent";
};
export function SubmitButton({
  className,
  isPending = false,
  disabled,
  children,
  variant = "primary"
}: SubmitButtonProps) {
  // The button should NEVER be enabled while it’s pending.
  const isPendingOrDisabled = isPending || disabled;
  return <button className={clsx(className, "base-button base-hover-button group relative text-white", variant === "primary" && "bg-primary hover:bg-primary-hover", variant === "accent" && "bg-accent hover:bg-accent-hover", isPending && "!cursor-default")} data-testid="submit-button" disabled={isPendingOrDisabled} type="submit" data-sentry-component="SubmitButton" data-sentry-source-file="SubmitButton.component.tsx">
      <span className={isPending ? "invisible" : "visible"}>{children}</span>

      {isPending ? <Spinner className="absolute h-2 w-2 fill-primary text-whisper group-disabled:fill-silver" /> : null}
    </button>;
}