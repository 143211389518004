import { clsx } from "clsx";
type ValueMarkerInvalidProps = {
  readonly className?: string;
};
export function ValueMarkerInvalid({
  className
}: ValueMarkerInvalidProps) {
  return <div className={clsx(className, "pointer-events-none flex h-2.5 w-2.5 select-none items-center justify-center rounded-full bg-error-500 p-px font-bold text-white transition-all motion-reduce:transition-none")} data-testid="value-marker-invalid" data-sentry-component="ValueMarkerInvalid" data-sentry-source-file="ValueMarkerInvalid.component.tsx">
      !
    </div>;
}