import type { RefObject } from "react";
import { useState } from "react";

export function useFormValidation(ref: RefObject<HTMLFormElement>) {
  const [isFormValid, setIsFormValid] = useState(false);

  function checkFormValidity() {
    setIsFormValid(Boolean(ref.current?.checkValidity()));
  }

  return {
    checkFormValidity,
    isFormValid,
  };
}
