export type ErrorMessageStatus =
  | "BLACKLISTED"
  | "FAILED"
  | "TRIAL_ALREADY_USED"
  | "UNAUTHORIZED"
  | "USER_ALREADY_EXISTS"
  | "NOT_FOUND"
  | "PASSWORD_REQUIREMENTS_NOT_MET";

export type Fetchable<Subject = undefined> =
  | Failed
  | Initial
  | Pending
  | Succeeded<Subject | undefined>;

type Initial = {
  readonly $class: "initial";
};

type Pending = {
  readonly $class: "pending";
};

type Succeeded<Subject = undefined> = {
  readonly $class: "succeeded";
  readonly value: Subject;
};

type Failed = {
  readonly $class: "failed";
  readonly errorCode?: number;
  readonly errorMessage: string;
};

export function initial<Subject = undefined>(): Fetchable<Subject> {
  return { $class: "initial" };
}

export function pending<Subject = undefined>(): Fetchable<Subject> {
  return { $class: "pending" };
}

export function succeeded<Subject = undefined>(
  value?: Subject,
): Fetchable<Subject> {
  return { $class: "succeeded", value };
}

export function failed<Subject = undefined>(
  errorMessage: string,
  errorCode?: number,
): Fetchable<Subject> {
  return { $class: "failed", errorCode, errorMessage };
}

export function isPending<Subject>(
  fetchable: Fetchable<Subject>,
): fetchable is Pending {
  return fetchable.$class === "pending";
}

export function hasFailed<Subject>(
  fetchable: Fetchable<Subject>,
): fetchable is Failed {
  return fetchable.$class === "failed";
}
