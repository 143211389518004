import { clsx } from "clsx";
import { OutlineCheckIcon } from "@/components/Icons/components/HeroIcons/OutlineCheckIcon.component";
type ValueMarkerValidProps = {
  readonly className?: string;
};
export function ValueMarkerValid({
  className
}: ValueMarkerValidProps) {
  return <div className={clsx(className, "pointer-events-none flex h-2.5 w-2.5 select-none items-center justify-center rounded-full bg-success-500 p-px transition-all motion-reduce:transition-none")} data-testid="value-marker-valid" data-sentry-component="ValueMarkerValid" data-sentry-source-file="ValueMarkerValid.component.tsx">
      <OutlineCheckIcon className="h-full w-full text-white" data-sentry-element="OutlineCheckIcon" data-sentry-source-file="ValueMarkerValid.component.tsx" />
    </div>;
}